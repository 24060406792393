<template>
  <section v-if="isGetRequestPending" class="edit-organization">
    <p-loading />
  </section>
  <section v-else-if="!item" class="edit-organization">
    <p-not-found />
  </section>
  <section v-else class="edit-organization">
    <header>
      <h1>Edit Organization</h1>
      <div>
        <div class="action-wrapper">
          <p-button v-if="$hasPermission('organizations.write')" color="secondary" @click="confirmDelete">Delete</p-button>
        </div>
      </div>
    </header>
    <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" @submit.prevent="submit">
      <div class="form-row">
        <p-text-field v-model="name" :label="'Name'" autocomplete="off" :disabled="true" />
        <div v-if="!$v.name.required" class="error">Name is required</div>
      </div>
      <div class="form-row">
        <p-tags
          v-model="aliases"
          :label="'Aliases'"
          :disabled="isUpdateRequestPending || !$hasPermission('organizations.write')"
          placeholder=""
          :options="[]"
        />
      </div>
      <div class="form-row">
        <p-text-field v-model="shortName" :label="'Prefix'" autocomplete="off" :disabled="false" />
        <div v-if="!$v.shortName.required" class="error">Short name is required</div>
      </div>
      <div class="form-row">
        <p-tags
          v-model="domains"
          :label="'Correspondence domains'"
          :disabled="isUpdateRequestPending || !$hasPermission('organizations.write')"
          placeholder=""
          :options="[]"
        />
      </div>
      <div class="form-row">
        <p-tags
          v-model="regexs"
          :label="'Regexs'"
          :disabled="isUpdateRequestPending || !$hasPermission('organizations.write')"
          placeholder=""
          :options="[]"
        />
      </div>

      <div v-if="isUpdateRequestFailed" class="error">{{ errorMessage }}</div>
      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button
          v-if="$hasPermission('organizations.write')"
          color="primary"
          type="submit"
          :disabled="isUpdateRequestPending || ($v.$anyDirty && $v.$invalid)"
          >Save</p-button
        >
      </div>
    </form>

    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import Loading from '@/components/common/Loading';
import TextField from '@/components/common/TextField';
import Tags from '@/components/common/Tags';
import Button from '@/components/common/Button';
import NotFound from '@/components/NotFound';

export default {
  components: {
    'p-text-field': TextField,
    'p-tags': Tags,
    'p-button': Button,
    'p-loading': Loading,
    'p-not-found': NotFound
  },
  data() {
    return {
      name: '',
      shortName: '',
      aliases: [],
      domains: [],
      regexs:[],
      errorMessage: ''
    };
  },
  computed: {
    ...mapState({
      item: s => s.organizations.item,
      isGetRequestPending: s => s.organizations.isGetRequestPending,
      isUpdateRequestPending: s => s.organizations.isUpdateRequestPending,
      isUpdateRequestFailed: s => s.organizations.isUpdateRequestFailed,
      isDeleteRequestPending: s => s.organizations.isDeleteRequestPending,
      isDeleteRequestFailed: s => s.organizations.isDeleteRequestFailed
    })
  },
  validations: {
    name: {
      required
    },
    shortName: {
      required
    }
  },
  watch: {
    '$route.params': {
      async handler() {
        await this.$store.dispatch('organizations/getById', this.$route.params.id);
        this.name = this.item && this.item.name;
        this.shortName = this.item && this.item.shortName;
        this.aliases = this.item && this.item.aliases;
        this.domains = this.item && this.item.domains;
        this.regexs = this.item && this.item.regexs;
      },
      immediate: true
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        this.errorMessage = '';
        await this.$store.dispatch('organizations/update', {
          id: this.$route.params.id,
          shortName: this.shortName.trim(),
          aliases: Array.isArray(this.aliases) ? this.aliases : [],
          domains: Array.isArray(this.domains) ? this.domains : [],
          regexs: Array.isArray(this.regexs) ? this.regexs : []
        });

        if (!this.isUpdateRequestFailed) {
          this.$router.push({ path: `/organizations` });
        }
      } catch (e) {
        this.errorMessage = 'Failed to update organization. Check your input and try again.';
      }
    },
    back() {
      this.$router.back();
    },
    async confirmDelete() {
      const { name } = this.item;

      const confirmResult = await this.$confirm({
        title: 'Delete organization?',
        message: `Are you sure you want to delete organization '${name}'? This action can't be undone.`,
        confirm: 'Delete'
      });

      if (!confirmResult) {
        return;
      }

      const lock = this.$lock();

      await this.$store.dispatch('organizations/delete', this.$route.params.id);

      lock.release();

      if (this.isDeleteRequestFailed) {
        this.$toast.error({
          title: 'Failed to delete organization',
          message: `Please, try again later or contact our development team.`
        });
      } else {
        this.$toast.success({
          title: 'Organization deleted',
          message: `Organization '${name}'  was successfully deleted.`
        });
        this.$router.push({ path: `/organizations` });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-organization {
  width: 100%;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;
    position: relative;

    .action-wrapper {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
  form {
    padding: 2rem;
    max-width: 480px;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
    &.dirty {
      .error {
        display: block;
      }
    }
  }
}
</style>
